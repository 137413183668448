<script>
import {
  ArrowUpIcon,
  UserIcon,
  MailIcon,
  PhoneIcon,
  BookIcon,
  MessageCircleIcon, ArrowRightIcon, MapPinIcon, UserCheckIcon, MonitorIcon, ClockIcon, DollarSignIcon, BriefcaseIcon,
} from "vue-feather-icons";
import Navbar from "@/components/navbar0";

import Footer from "@/components/footer";


import store from '@/store/store';

/**
 * Page-job-apply component
 */
export default {
  data() {
    return {
      input2sql: {
        vacancy_id: 1,
        user_id: 1,
        name: '',
        email: '',
        phone: '',
      },
      file: null,
      job: {job_id: "1",},
      resumeFile: null,
      job_createdDate: '',
    }
  },
  components: {
    Navbar,
    Footer,
    UserIcon, MailIcon, PhoneIcon,
    ArrowUpIcon,
    ArrowRightIcon,
    MapPinIcon,
    UserCheckIcon,
    MonitorIcon,
    BookIcon,
    ClockIcon,
    DollarSignIcon,
    BriefcaseIcon
  },

  mounted() {
    this.getJobDetails();
  },

  methods: {

    toJobApplication(){
      let route = this.job.original_link;
      window.open(route, '_blank');
    },

    getJobDetails() {


      this.input2sql.user_id = store.state.user_id;
      this.input2sql.vacancy_id = this.$route.params.vacancy_id;
      console.log('inside getJobDetails ')
      console.log(this.input2sql)

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/job-description-public/get',
        data: this.input2sql,
      }).then((res) => {
        this.job = res.data.jobDescription;
        this.job_createdDate = res.data.date_from;
        console.log('resume')
        console.log(res.data);
      });


    },



    onChange (event) {
      this.file = event.target.files[0]
    },
    jobApply() {
      console.log(this.file);

      this.resumeFile = new FormData();
      this.resumeFile.append('file', this.file);
      this.resumeFile.append('name', this.input2sql.name);
      this.resumeFile.append('email', this.input2sql.email);
      this.resumeFile.append('phone', this.input2sql.phone);
      this.resumeFile.append('user_id', store.state.user_id);
      this.resumeFile.append('vacancy_id', this.$route.params.vacancy_id);
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/apply-for-job',
        data: this.resumeFile,
        headers:{ 'content-type' : 'multipart/form-data' },
      }).then( (res) => {
        console.log(res.data)
        if (res.data.status==='done') {
          window.open(this.$hostnamefront + '/application-success')
        }
      });

    }



  }

}

</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h1>{{job.job_title}}</h1>
                <p>{{job_createdDate}}</p>
              <div class="page-next">

              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Job apply form Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-7">
            <div class="card custom-form border-0">
              <div class="card-body">
                <form class="rounded shadow p-4">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label
                          >ФИО :<span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <user-icon class="fea icon-sm icons"></user-icon>
                          <input
                            name="name"
                            id="name"
                            type="text"
                            v-model="input2sql.name"
                            class="form-control pl-5"
                            placeholder="ФИО :"
                          />
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label
                          >Email :<span class="text-danger">*</span></label
                        >
                        <div class="position-relative">
                          <mail-icon class="fea icon-sm icons"></mail-icon>
                          <input
                            name="email"
                            id="email"
                            type="email"
                            v-model="input2sql.email"
                            class="form-control pl-5"
                            placeholder="Email :"
                          />
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label
                          >Номер телефона :<span class="text-danger"
                            >*</span
                          ></label
                        >
                        <div class="position-relative">
                          <phone-icon class="fea icon-sm icons"></phone-icon>

                          <input
                              name="number"
                              id="number"
                              type="phonenumber"
                              v-model="input2sql.phone"
                              class="form-control pl-5"
                              placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                   <!-- <div class="col-md-6">
                      <div class="form-group">
                        <label>Job Title :</label>
                        <div class="position-relative">
                          <book-icon class="fea icon-sm icons"></book-icon>
                          <input
                            name="subject"
                            id="subject"
                            class="form-control pl-5"
                            placeholder="Title :"
                          />
                        </div>
                      </div>
                    </div> -->
                    <!--end col-->
                   <!-- <div class="col-md-6">
                      <div class="form-group">
                        <label>Types of jobs :</label>
                        <select
                          class="form-control custom-select"
                          id="Sortbylist-Shop"
                        >
                          <option>All Jobs</option>
                          <option>Full Time</option>
                          <option>Half Time</option>
                          <option>Remote</option>
                          <option>In Office</option>
                        </select>
                      </div>
                    </div> -->
                    <!--end col-->

                    <!--end col-->
                    <div class="col-md-12">
                      <div class="form-group position-relative">
                        <label>Загрузить Cv / Резюме :</label>
                        <input
                          type="file"
                          class="form-control-file"
                          ref="resumeFile"
                          @change="onChange"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-12">

                          <label>
                            Нажимая кнопку «Отправить», вы принимаете условия
                            <a href="/terms" class="text-primary">Правил</a> и
                            <a href="/license" class="text-primary">Соглашения</a> об использовании сайта.
                          </label>
                        </div>

                    <!--end col-->
                  </div>
                  <!--end row-->
                  <div class="row">
                    <div class="col-sm-12">
                      <a class="btn btn-primary"
                        @click="jobApply"
                      >Отправить</a>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
                <!--end form-->
              </div>
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Job apply form End -->
    <!--end section-->
    <Footer />

    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
